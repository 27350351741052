import i18n from "i18next";
import {I18n} from "next-translate";
import setLanguage from "next-translate/setLanguage";
import TransText from "next-translate/TransText";
import nextUseTranslation from "next-translate/useTranslation";
import {useMemo} from "react";

import {I18nNamespace} from "./i18n-namespaces";
import Trans from "./Trans";
import {fixInterpolation} from "./i18nFixInterpolation";

// When translating new text, make sure to update the translations too:
// https://mdcollab.atlassian.net/wiki/spaces/engineering/pages/680329381/Localization
const useTranslation = (namespace?: I18nNamespace): I18n => {
  const i18nWithBadInterpolation = nextUseTranslation(namespace);

  return useMemo<I18n>(
    () => ({
      t: fixInterpolation(i18nWithBadInterpolation.t),
      lang: i18nWithBadInterpolation.lang,
    }),
    [i18nWithBadInterpolation],
  );
};

// TODO: Replace this Trans with server-compatible version as it also works on client
export {i18n, useTranslation, Trans, TransText, setLanguage};

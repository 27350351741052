import {getDefaultHeaders} from "./monolith";
import {validateHeaders as validateHeadersMonolith} from "./monolithValidation";

export function limitToNextApi(
  delegate: typeof fetch,
): (url: string, init?: RequestInit) => Promise<Response> {
  return async (url: string, init?: RequestInit) => {
    if (!url.startsWith("/")) {
      throw new Error(`Calls to this app's API should use relative paths. Got ${url}`);
    }
    const defaultHeaders = getDefaultHeaders();

    if (init) {
      const {headers, ...otherInitOptions} = init;
      return delegate(url, {
        headers: {
          ...defaultHeaders,
          ...validateHeaders(headers),
        },
        ...otherInitOptions,
      });
    } else {
      return delegate(url, {headers: defaultHeaders});
    }
  };
}

/**
 * We'll probably never hit a use-case where we pass headers as anything but a raw JS object...
 * but this just makes sure of it.
 *
 * If this error starts happening, we can come back and implement it for real.
 */
function validateHeaders(headers?: HeadersInit): Record<string, string> {
  const defaulted = validateHeadersMonolith(headers);

  // Careful here! The carbon-website-next app is hosted on Vercel, which is _not_ HIPAA compliant.
  // This error is a failsafe to make sure that we don't accidentally send auth tokens to server
  // code hosted on Vercel machines.
  const authHeader = Object.keys(defaulted)
    .map(key => key.toLowerCase())
    .find(key => key === "authorization");
  if (authHeader) {
    throw new Error("Authorization headers shouldn't be sent to endpoints on this app");
  }
  return defaulted;
}

import {LocalStorageKey, getFromLocalStorage, setInLocalStorage} from "../localStorage";
import {CFGeolocation} from "../locationTypes";
import {useLocalStorageItem} from "./useBrowserStorage";

export const getGeolocation = () => getFromLocalStorage(LocalStorageKey.Geolocation);

export const setGeolocation = (coordinates: CFGeolocation) =>
  setInLocalStorage(LocalStorageKey.Geolocation, coordinates);

export const useGeolocation = () => useLocalStorageItem(LocalStorageKey.Geolocation);

import {ApptSlot} from "@services/monolith/availability";
import memoizee from "memoizee";

import {getApptCachedUrl} from "../../workers/common/getApptCachedUrl";
import {ApptSlotsWorkerParams} from "../../workers/common/types";
import {MsMap} from "../constants/MsMap";

// eg:
// fetchCachedSlot({specialtyId: SpecialtyId.URGENT_CARE, locationId: LocationId.BERKELEY})
// fetchCachedSlot({specialtyId: SpecialtyId.VIRTUAL_URGENT_CARE})
const fetch_ = (args: ApptSlotsWorkerParams) =>
  fetch(getApptCachedUrl(args))
    .then(r => {
      if (r.ok) {
        return <Promise<ApptSlot[]>>r.json();
      }
      return [] as ApptSlot[];
    })
    .then(slots => slots?.filter(s => s?.time > Date.now()) || []);

const memoizedFetch = memoizee(fetch_, {
  maxAge: MsMap.ONE_MINUTE * 5, // memoize for 5 mins
  promise: true, // prevents caching bad response (exceptions)
  normalizer: JSON.stringify,
});

const argsToPick: (keyof ApptSlotsWorkerParams)[] = [
  "selectedState",
  "specialtyId",
  "practiceId",
  "locationId",
  "doctorId",
  "reasonId",
  "days",
  "limit",
  "from",
  "to",
  "timezone",
  "patientId",
];

export const fetchCachedSlot = (args: ApptSlotsWorkerParams): Promise<ApptSlot | null> => {
  const cleanedArgs = args.chPick(argsToPick);
  return memoizedFetch(cleanedArgs).then(slots => slots?.[0] || null);
};

export const fetchCachedSlots = (args: ApptSlotsWorkerParams): Promise<ApptSlot[]> => {
  const cleanedArgs = args.chPick(argsToPick);
  return memoizedFetch(cleanedArgs);
};

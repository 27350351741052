import {getHostWithProtocol} from "./host";

// TODO: Some day we can enable typed routes and then this generic will be needed
//  See: https://nextjs.org/docs/app/building-your-application/configuring/typescript#statically-typed-links
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LinkHref<_T = string> = string;

type URLBuilder = {
  /**
   * Add a query parameter, without removing any existing query parameters.
   */
  appendQueryParam(key: string, value: unknown): URLBuilder;
  /**
   * Add multiple query parameters, without removing any existing query parameters.
   */
  appendQueryParams(params: Record<string, unknown>): URLBuilder;
  /**
   * Set a query parameter, removing any existing query parameters with the same key.
   */
  setQueryParam(key: string, value: unknown): URLBuilder;
  /**
   * Set multiple query parameters, removing any existing query parameters with the same keys.
   */
  setQueryParams(params: Record<string, unknown>): URLBuilder;
  /**
   * Replace a dynamic segment in the path with a value.
   * @param segmentValue The value to replace the segment with.
   * @param segmentName The name of the segment to replace. Defaults to "slug".
   */
  replaceSegment(segmentValue: string, segmentName?: string): URLBuilder;
  /**
   * Add the origin of the URL to the output. If this is called for a
   * relative path, it will become an absolute URL. This has no effect
   * on an absolute URL. The NEXT_PUBLIC_DOMAIN is the default origin
   * if one is not passed.
   */
  withOrigin(origin?: string): URLBuilder;
  /**
   * Create the final URL. This should always be the last method called.
   */
  build(): LinkHref;
};

// The JS URL object does not support relative URLs, so we need to use a
// placeholder origin to build up the URL and then remove it at the end.
const placeholderOrigin = "https://example.com";

function _createURLBuilder<T extends string>(
  basePath: LinkHref<T>,
  hostname: string,
  shouldStripOrigin: boolean = true,
): URLBuilder {
  const url = new URL(basePath, hostname);

  function _new(stripOrigin: boolean = shouldStripOrigin): URLBuilder {
    return _createURLBuilder(build(), hostname, stripOrigin);
  }

  function appendQueryParam(key: string, value: unknown): URLBuilder {
    url.searchParams.append(key, String(value));
    return _new();
  }

  function appendQueryParams(params: Record<string, unknown>): URLBuilder {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, String(value));
    });
    return _new();
  }

  function setQueryParam(key: string, value: unknown): URLBuilder {
    url.searchParams.set(key, String(value));
    return _new();
  }

  function setQueryParams(params: Record<string, unknown>): URLBuilder {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, String(value));
    });
    return _new();
  }

  function replaceSegment(segmentValue: string, segmentName: string = "slug"): URLBuilder {
    const pathname = url.pathname.replace(`[${segmentName}]`, segmentValue);
    url.pathname = pathname;
    return _new();
  }

  function withOrigin(origin?: string): URLBuilder {
    return _createURLBuilder(build(), origin ?? getHostWithProtocol(), false);
  }

  function build(): LinkHref {
    const fullUrl = url.toString();
    // Remove the hostname from the URL if it's the default
    return (
      shouldStripOrigin && fullUrl.startsWith(placeholderOrigin)
        ? fullUrl.replace(placeholderOrigin, "")
        : fullUrl
    ) as LinkHref;
  }

  return {
    appendQueryParam,
    appendQueryParams,
    setQueryParam,
    setQueryParams,
    replaceSegment,
    withOrigin,
    build,
  };
}

/**
 * Build up a URL in steps, starting with a base path beginning with /.
 *
 * @example
 * const url = createURLBuilder("/get-care", "https://www.example.com").build();
 * // url === "https://www.example.com/get-care"
 *
 * @example
 * const url = createURLBuilder("/get-care")
 *   .appendQueryParam("foo", "bar")
 *   .build();
 * // url === "/get-care?foo=bar"
 *
 * @example
 * const url = createURLBuilder("/get-care/[slug]")
 *   .replaceSegment("foo")
 *   .build();
 * // url === "/get-care/foo"
 *
 * @example
 * const url = createURLBuilder("/get-care")
 *   .withOrigin()
 *   .build();
 * // url === "https://carbonhealth.com/get-care?foo=bar"
 *
 *
 * @param baseUrl The URL path to start with, beginning with a slash.
 * @param hostName The hostname to use, including protocol. Defaults to the
 *   current hostname for the client.
 * @returns A URL builder that can be used to build up a URL in steps.
 */
export default function createURLBuilder<T extends string>(
  basePath: LinkHref<T>,
  hostname?: string,
): URLBuilder {
  return _createURLBuilder(basePath, hostname ?? placeholderOrigin);
}

export const milesBetweenCoordinates = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  return dist;
};

/**
 * Get the number of miles between two coordinates, rounded to the given scale.
 *
 * @param scale The number of digits after the decimal place. If 0, this rounds to the nearest
 *   integer. If 1, to the nearest tenth. etc.
 */
export const roundedMilesBetweenCoordinates = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
  scale: number = 0,
) =>
  scale === 0
    ? Math.round(milesBetweenCoordinates(lat1, lng1, lat2, lng2))
    : parseFloat(milesBetweenCoordinates(lat1, lng1, lat2, lng2).toFixed(scale));

export const wholeMilesBetweenCoordinates = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
) => Math.floor(milesBetweenCoordinates(lat1, lng1, lat2, lng2));

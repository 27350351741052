import {AppDispatch, actions} from "src/store";

import {ConfigState} from "../../store/types";
import {fetchLocationsAndPractices} from "../../utils/fetchLocationsAndPractices";

const isPracticeDataSet = (config: ConfigState) =>
  Boolean(config.allSpecialties && config.locations.length && config.practices);

export const setPracticeData = async (config: ConfigState, dispatch: AppDispatch) => {
  if (!isPracticeDataSet(config)) {
    const [{practices, locations}, {default: allSpecialties}] = await Promise.all([
      fetchLocationsAndPractices(),
      import("../../../public/static/data/localSpecialties.json"),
    ]);

    dispatch(
      actions.setConfig({
        practices,
        locations,
        allSpecialties,
      }),
    );
  }
};

import {getCookie as nextGetCookie} from "cookies-next";
import Cookies from "js-cookie";
import {debounce, delay, values} from "lodash";
import moment, {MomentInput} from "moment-timezone";
import Link from "next/link";
import {i18n} from "ni18n";
import React, {FocusEvent, useEffect, useRef, useState} from "react";
import {Address} from "../../_services/types";
import {PracticeId} from "../../constants/practiceIds";
import {RootStateLocation, RootStatePractice} from "../../store/types";
import {defaultNS, dev, isBrowser, laTimezone} from "./_constants";
import * as types from "./types";
import {IncomingMessageWithCookies} from "@services/api";

export const V5Img = ({src, alt, className, ...props}: types.ImgProps): React.ReactElement => {
  const v5prefix = "/static/img/v5";

  return (
    <img
      {...props}
      src={`${v5prefix}${src}`}
      alt={alt || src}
      className={`db ${className || ""}`}
    />
  );
};

export const getAddress = (a: Address): string | false =>
  a && `${a.firstLine}${a.aptNumber ? `, #${a.aptNumber}` : ""}, ${a.city} ${a.state} ${a.zip}`;

export const getAddressShorter = (a: Address): string | false =>
  a && `${a.firstLine}${a.aptNumber ? `, #${a.aptNumber}` : ""}, ${a.city}`;

export const getPeriod = (m: MomentInput, timePeriods: number[]): false | number => {
  const time = moment(m);

  if (!time || !time.isValid()) {
    return false;
  } // if we can't find a valid or filled moment, we return.

  const currentHour = parseInt(time.format("HH"));

  let pp = null;
  timePeriods.every((t, i) => {
    pp = i;
    return currentHour >= t && currentHour < timePeriods[timePeriods.length - 1];
  });

  // @ts-expect-error TS2322: Type 'null' is not assignable to type 'number | false'.
  return pp;
};

export const getCookie = ({req, name}: {req?: IncomingMessageWithCookies; name: string}) =>
  req && name ? nextGetCookie(name, {req}) : Cookies.get(name);

export const reducer = <T,>(currentState: T, newState: Partial<T>): T => ({
  ...currentState,
  ...newState,
});

export function useEffectAsync(effect: () => unknown, inputs: unknown[]): void {
  useEffect(() => {
    effect();
  }, inputs);
}

export function usePrevious<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useWindowSize(): {width: number | undefined; height: number | undefined} {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    const lazilyHandleResize = debounce(handleResize, 100);

    window.addEventListener("resize", lazilyHandleResize);
    return () => window.removeEventListener("resize", lazilyHandleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const useInterval = (callback: () => void, dly: number, pause: boolean): void => {
  const savedCallback = useRef(() => undefined);

  // Remember the latest function.
  useEffect(() => {
    // @ts-expect-error TS2322: Type '() => void' is not assignable to type '() => undefined'.
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (!pause) {
      const id = setInterval(tick, dly);
      return () => clearInterval(id);
    }
  }, [callback, dly, pause]);
};

// @ts-expect-error TS7006: Parameter 'f' implicitly has an 'any' type.
export const dly = (f, t = 0): number => delay(f, t, "later");

export const ands = (lng: string, ar: string[]): string =>
  ar
    .map(
      (a, i) =>
        `${i > 0 ? (i === ar.length - 1 ? ` ${getT(lng, "and")} ` : ", ") : ""}${getT(lng, a)}`,
    )
    .join("");

export const isLocationOpen = (location: RootStateLocation, sId?: string): boolean => {
  const hours = location.specialties[sId || location.specialtyIds[0]]?.hours;
  if (!hours || hours.isEmpty()) return false;

  const _now = moment().tz(location.timezone || laTimezone);

  const [_day, _time] = [_now.format("e"), _now.format("HH:mm")];

  const todayHours = hours && hours.find(({day}) => `${day}` === _day);

  if (!todayHours) return false;

  return _time >= todayHours.from && _time <= todayHours.to;
};

export const isLocationOpen2 = (location: RootStateLocation): boolean => {
  const _now = moment().tz(location.timezone || laTimezone);
  return location.specialties.vals().some(s => {
    const hours = s.hours;
    if (!hours || hours.isEmpty()) return false;
    const [_day, _time] = [_now.format("e"), _now.format("HH:mm")];
    const todayHours = hours && hours.find(({day}) => `${day}` === _day);
    if (!todayHours) return false;
    return _time >= todayHours.from && _time <= todayHours.to;
  });
};

// get translation outside
export const getT = (lng = "en", key: string): string =>
  i18n.getResource(isBrowser() && window.lng ? window.lng : lng, defaultNS, key) || key;

// @ts-expect-error TS7006, TS7006: Parameter 'days' implicitly has an 'any' type.,  Parameter 'lng' implicitly has an 'any' type.
const getDayGroup = (days, lng) => {
  switch (days.sort().join()) {
    case "0,1,2,3,4,5,6":
      return getT(lng, "Every day");
    case "1,2,3,4,5":
      return getT(lng, "Weekdays");
    case "0,6":
      return getT(lng, "Weekends");
    default:
      // @ts-expect-error TS7006: Parameter 'd' implicitly has an 'any' type.
      return days.map(d => moment(d, "e").format("ddd")).join(", ");
  }
};

export const groupHours = (location: RootStateLocation, sId: string, lng: string): any[] => {
  const hours = location.specialties[sId || location.specialtyIds[0]]?.hours;
  if (!hours || hours.isEmpty()) return [];

  return values((hours || []).groupBy(({from, to}) => `${from}-${to}`))
    .sortBy(group => Math.min(...group.map(({day}) => day)))
    .map(group => {
      const {from, to} = group[0];
      const days = group.map(({day}) => day);

      return {name: getDayGroup(days, lng), from, to};
    });
};

const staticImg = (src: string): string => `/static/img${src}`;
export const staticBgImg = (src: string): string => `url(${staticImg(src)})`;

export const getVirtualSpecialtyIds = (practices: RootStatePractice[]): string[] =>
  // @ts-expect-error TS2532: Object is possibly 'undefined'.
  practices
    ?.findById(PracticeId.CARBON)
    .specialties.vals()
    .filter(s => s.isVirtual)
    .map(s => s.id)
    .compact();

const GA = ({category, action, label, value = 0}: types.GAParams): boolean | unknown => {
  const wGa = window.ga;
  return !dev && wGa && wGa("send", "event", category, action, label, value);
};

/*
Usage examples:
<div><Btn onClick={() => trackToPatient()}>1</Btn></div>
<div><Btn onClick={() => trackToPatient({ga: {label: "test"}})}>3</Btn></div>
<div><Btn onClick={() => trackToPatient({ga: {label: "test"}})}>4</Btn></div>
<div><Btn onClick={() => trackToPatient({ga: {category: "test", label: "test"}})}>5</Btn></div>
*/
export const trackToPatient = (params?: {ga?: types.GA}): void => {
  const tatariTrackType = "AddToCart";

  const newGa = {
    category: params?.ga?.category || "Appt Booking CTA",
    action: params?.ga?.action || "CTA Click",
    label: params?.ga?.label || "None",
  };

  if (dev) {
    console.log(`tatari.track("${tatariTrackType}");`);
    if (newGa.label) console.log(`GA(${JSON.stringify(newGa)});`);
  }

  if (typeof tatari !== "undefined") {
    tatari?.track(tatariTrackType);
  }

  if (newGa.label) GA(newGa);
};

export const unslugify = (str = ""): string => str.replace(/-/g, " ");

// TODO: Accept optional country code
export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

export const stringToUsd = (usd: number): string => {
  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return usdFormatter.format(usd / 100);
};

export const Alink = ({
  href,
  children,
}: {
  href: string;
  children: string;
  className?: string;
}): React.ReactElement => <Link {...{href}}>{children}</Link>;

export const scrollToId = (id: string): void =>
  document?.getElementById(id)?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });

/**
 * Recursively finds and joins strings from a Trans component for searching.
 */
export const getTransString = (trans: React.ReactElement | string): string => {
  const strings: string[] = [];
  const inspectChild = (node: React.ReactElement | string) => {
    if (typeof node === "string") {
      strings.push(node);
    } else if (typeof node?.props?.children === "string") {
      strings.push(node.props.children);
    } else if (Array.isArray(node?.props?.children)) {
      // @ts-expect-error TS7006: Parameter 'child' implicitly has an 'any' type.
      node.props.children.forEach(child => inspectChild(child));
    } else if (node?.props?.children) {
      inspectChild(node.props.children);
    }
  };
  inspectChild(trans);
  return strings.compact().join(" ");
};

// import scripts dynamically (eg zendesk widget)
export const importer = {
  // @ts-expect-error TS7031, TS7031: Binding element 'url' implicitly has an 'any' type.,  Binding element 'id' implicitly has an 'any' type.
  url: ({url, id}) =>
    new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.addEventListener("load", () => resolve(script), false);
      script.addEventListener("error", () => reject(script), false);
      document.body.appendChild(script);
    }),
  // @ts-expect-error TS7006: Parameter 'urls' implicitly has an 'any' type.
  urls: urls => Promise.all(urls.map(importer.url)),
};

export const ifChildrenLoseFocus =
  (f: VoidFunction): ((e: FocusEvent) => void) =>
  (e: FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      f();
    }
  };

export const focusTo = (el: string): void => {
  const currentEl: HTMLElement | null = document.querySelector(el);
  currentEl?.focus();
};

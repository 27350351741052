import {Translate} from "next-translate";

type TranslateOptions<T = string> =
  | (Omit<Parameters<Translate>[2], "default"> & {
      default?: T;
    })
  | undefined;
type I18nKey = Parameters<Translate>[0];

const addDefaultToOptions = <T>(options: TranslateOptions<T>, i18nKey: I18nKey) => ({
  ...options,
  default: options?.default || (typeof i18nKey === "string" && i18nKey) || undefined,
});

export const fixInterpolation =
  (t: Translate): Translate =>
  (i18nKey, query, options) =>
    t(i18nKey, query, addDefaultToOptions(options, i18nKey));

import {LocalStorageKey, getFromLocalStorage, setInLocalStorage} from "../localStorage";
import {UserSelectedLocation} from "../locationTypes";
import {setCookieClient} from "./cookies";
import {useLocalStorageItem} from "./useBrowserStorage";

const defaultUserSelectedLocation: UserSelectedLocation = {};

/**
 * Get the user's selected location from local storage.
 *
 * Use this function if you don't want the component to re-render on
 * changes to location. If you do want it to re-render, use
 * useUserSelectedLocation instead.
 */
export const getUserSelectedLocation = () =>
  getFromLocalStorage(LocalStorageKey.UserSelectedLocation) ?? defaultUserSelectedLocation;

/**
 * Note: This should only be called client-side.
 */
export const setUserSelectedLocation = (location: UserSelectedLocation) => {
  setInLocalStorage(LocalStorageKey.UserSelectedLocation, location);
  setCookieClient(LocalStorageKey.UserSelectedLocation, location);
};

/**
 * Get the user's selected location from local storage.
 *
 * Use this function if you want the component to re-render on
 * changes to location. If you don't want it to re-render, use
 * getUserSelectedLocation instead.
 */
export const useUserSelectedLocation = () =>
  useLocalStorageItem(LocalStorageKey.UserSelectedLocation) ?? defaultUserSelectedLocation;

import {practiceIds} from "../../src/constants/practiceIds";
import {WorkerUrl} from "../../src/constants/workerUrls";

export const corsHeaders = {
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Methods": "*",
  "Access-Control-Allow-Origin": "*",
};

export const workersDataUrl = "https://carbonhealth.com/static/data/localWorkersData.json";

export const headers = {"Content-Type": "application/json"};

export const defaultDays = 30;
export const defaultDaysForVirtual = 30;

export const defaultLimit = 3;

export const cachedSlotsAPIRootUrl = `${WorkerUrl.PROD}/apptslots`;

export const laTimezone = "America/Los_Angeles";
export const popularReasonsCount = 8;
export const practiceIdsCombined = JSON.stringify(practiceIds.join(","));

export enum CfCacheTag {
  APPT_SLOTS = "APPT_SLOTS",
  PRICING = "PRICING",
}

export const sfCenterPos = {x: 37.7749, y: -122.4194, regionCode: "CA"};

export const fakeCF = {
  timezone: laTimezone,
  latitude: `${sfCenterPos.x}`, // cf.latitude should be string
  longitude: `${sfCenterPos.y}`,
};

import Cookies from "js-cookie";

/**
 * Set a cookie from the client-side.
 *
 * DO NOT call this function on the server. Instead, use
 * the cookies function:
 * https://nextjs.org/docs/app/api-reference/functions/cookies
 */
export function setCookieClient(
  name: string,
  value: string | Record<string, unknown>,
  expireDays?: number,
) {
  const expires = expireDays ? getExpirationDate(expireDays) : undefined;
  Cookies.set(name, JSON.stringify(value), {expires});
}

function getExpirationDate(days: number): Date {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // days * hours * minutes * seconds * milliseconds
  return date;
}

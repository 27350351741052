import "isomorphic-fetch";

import {limitToMonolith} from "./monolith";
import {limitToNextApi} from "./nextApi";
import {withResponseValidation} from "./responseValidation";
import {withSentryErrorLogging} from "./sentryErrorLogging";

/**
 * Get a fetch-like function suitable for making calls to the monolith.
 *
 * @param optionOverrides Options to customize the returned fetch function. See the comments
 *   on the Options type for more details.
 */
export default (optionOverrides: Partial<Options> = {}) =>
  limitToMonolith(withValidation(optionOverrides));

/**
 * Get a fetch-like function suitable for making calls to /api routes served by
 * carbon-website-next.
 *
 * @param optionOverrides Options to customize the returned fetch function. See the comments
 *   on the Options type for more details.
 */
export const nextApi = (optionOverrides: Partial<Options> = {}) =>
  limitToNextApi(withValidation(optionOverrides));

/**
 * Get a fetch-like function with slightly more aggressive error handling and logging.
 *
 * Rather than returning a Promise.resolve() on 4xx and 5xx, it returns errors. All 5xx's
 * are errors, and 4xx's are _generally_ considered errors unless the optionOverrides say
 * otherwise.
 *
 * Any 4xx errors are logged to sentry. 5xx errors are not, since they imply as server-side bug
 * (which presumably should have its own metrics & error monitoring).
 *
 * @param optionOverrides Options to customize the returned fetch function. See the comments
 *   on the Options type for more details.
 */
export function withValidation(optionOverrides: Partial<Options> = {}): typeof fetch {
  const {allowedStatusCodes} = {...defaults, ...optionOverrides};
  return withResponseValidation(withSentryErrorLogging(fetch, {allowedStatusCodes}), {
    allowedStatusCodes,
  });
}

/**
 * Fetch options to customize the behavior of the returned function.
 */
type Options = {
  /**
   * Typically, 4xx errors indicate are client-side bugs which we need to fix. Our enhanced fetch
   * functions return rejected Promises and log these to Sentry, which ultimately alerts
   * engineering that there's a bug.
   *
   * This isn't true of all endpoints though. Some product use-cases expect a 404, or
   * want to behave differently (e.g. redirect the user to a login page) on 401s.
   *
   * This option lets callers define the 4xx codes which should _not_ be treated as errors.
   * In these cases the fetch will return a successful Promise<Response> if the server returns them.
   */
  allowedStatusCodes: number[];
};

const defaults: Options = {
  allowedStatusCodes: [],
};

/**
 * We'll probably never hit a use-case where we pass headers as anything but a raw JS object...
 * but this just makes sure of it.
 *
 * If this error starts happening, we can come back and implement it for real.
 */

export function validateHeaders(headers?: HeadersInit): Record<string, string> {
  if (headers instanceof Headers || Array.isArray(headers)) {
    throw new Error("Support for Header objects and arrays is not yet implemented.");
  }
  return headers ?? {};
}

import {configureStore, createAction} from "@reduxjs/toolkit";
import {pick} from "lodash";
import {HYDRATE as REDUX_HYDRATE, createWrapper} from "next-redux-wrapper";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

import {dev} from "../components/_common/_constants";
import config, {setConfig} from "./slices/config";

export const HYDRATE = createAction<AppState>(REDUX_HYDRATE);

export const actions = {
  setConfig,
};

const reducer = {
  config,
} as const;
export const makeStore = (preloadedState = {}) => {
  // only let reducers be added to the store if they are in the reducer object
  const cleanedPreloadedState = pick(preloadedState, [Object.keys(reducer)]);

  return configureStore({
    reducer,
    devTools: dev,
    preloadedState: cleanedPreloadedState,
  });
};

const store = makeStore();

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useTypedDispatch: () => AppDispatch = useDispatch;

export const NextReduxWrapper = createWrapper<AppStore>(makeStore, {
  debug: false,
});

export default store;

import {ServerResponse} from "http";

import * as Sentry from "@sentry/nextjs";
import {isEmpty} from "lodash";
import moment from "moment";
import QueryString from "qs";

import {api, IncomingMessageWithCookies} from "../_services/api";
import {isBrowser} from "../components/_common/_constants";
import monolith from "./fetch/fetches";
import {parseUtmParams} from "./parseUtmParams";

export const visitTracking = async ({
  res,
  req,
}: {
  res?: ServerResponse;
  req?: IncomingMessageWithCookies;
}): Promise<{visitId: string; visitorId: string}> => {
  if (isBrowser()) throw new Error("Visit tracking is not allowed on client.");
  const query = req?.url ? QueryString.parse(req.url) : {};

  const utm = req?.url && parseUtmParams(query);
  const landingUrl = isBrowser()
    ? window.location.toString()
    : req
    ? `${/carbonhealth\.com/gi.test(req.headers.host || "") ? "https://" : ""}${req.headers.host}${
        req.url
      }`
    : "";

  const headers =
    !isEmpty(req) && req?.headers
      ? {
          ...(req.headers.referer ? {Referer: req.headers.referer} : {}),
        }
      : {};

  try {
    const tracking = await monolith({allowedStatusCodes: [403]})("/hib/visit-tracking", {
      method: "POST",
      headers: {
        ...headers,
        ...api.getDefaultHeaders(req, true),
      } as HeadersInit,
      body: JSON.stringify({utm, landingUrl, timezone: moment.tz.guess()}),
    });
    if (tracking.status == 403) {
      return {visitId: "", visitorId: ""};
    } else {
      const response = await tracking.json();
      api.setVisitCookies(response.visitId, response.visitorId, res, req);
      return {visitId: response.visitId, visitorId: response.visitorId};
    }
  } catch (e) {
    const msg = `endpoint: /hib/visit-tracking . error: ${e}`;
    console.log(msg);
    Sentry.captureException(msg);
    return {visitId: "", visitorId: ""};
  }
};
